<template>
    <div class="Release">
        <div class="hdcon">
            <div class="hd">
                <Icon name="arrow-left" @click="$router.back(-1)" />
                <div class="tit">发布品鉴论酒</div>
                <div class="btn">
                    <button @click="draftsave">存草稿</button>
                    <button class="fa" @click="Judgesave">发布</button>
                </div>
            </div>
        </div>
        <div class="slect_product" @click="selectproduct">
            <Icon name="plus" />
            选择品鉴的产品
        </div>
        <Popup v-model="showProduct" position="bottom">
            <Picker show-toolbar title="选择产品" :columns="columnsProduct" value-key="abbreviation_name" @confirm="onConfirm" @cancel="onCancel"/>
        </Popup>
        <div class="product" v-if="goodsinfo">
            <div class="dpic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="goodsinfo.wcj_images" />
            </div>
            <div class="con">
                <div class="name Ellipses1">
                    {{goodsinfo.brand_name}}-{{goodsinfo.store_name}}
                </div>
                <div class="fen">
                    综合评分：<span class="font-color">{{goodsinfo.score}}</span>
                </div>
                <div class="Rate">
                   <Rate v-model="score" allow-half :size="25" color="#ffd21e" void-icon="star" void-color="#eee"/>
                </div>
                <!-- <div class="kuang" style="border: 2px solid #eee;margin-top:0.2rem" v-if="code">
                    <Field v-model="number" rows="1" type="number" placeholder="输入授权码才能参与活动哦~" style="padding:2px" />
                </div>
                <button class="verification">查看</button> -->
            </div>
        </div>
        <div class="product_ver" v-if="code">
            <div class="search">
                <input  type="number" placeholder="请输入授权码"  v-model="number" />
                <button class="verification" @click="seeCode()">查看</button>
            </div>
            <div class="codeInfo" v-if="vcode" >
                <div class="prize-wine" v-if="vcode==1" >
                    <div class="info"><div class="red-font">恭喜您！</div>
                    通过此码可免费换兌一瓶同款，请填写信息进行换兌。</div>
                    <input class="name"  placeholder="请输入收货人姓名" v-model="userName" />
                    <input class="tel" type="number" placeholder="请输入手机号码" v-model="telNumber" />
                    <input class="city"  @click.stop="cityShow = true"  placeholder="请选择省市区" v-model="city" />
                        <CitySelect
                        v-model="cityShow"
                        :callback="result2"
                        :items="district"
                        provance=""
                        city=""
                        area=""
                        ></CitySelect>
                    <input class="detailInfo"  placeholder="请输入详情地址" v-model="detailInfo"  />
                    <input class="postalCode"  type="number" placeholder="请输入邮政编码" v-model="postalCode"  />
                    <div class="weixinaddr" @click.stop="getWxAddr()"><span class="iconfont iconweixin"></span>填充微信收货地址</div>
                </div>
                <div class="prize-money" v-if="vcode==2">
                    <div class="info"><div class="red-font">恭喜您！</div>
                    添加此码进行发布评论可现金奖励，发布成功后请到商城会员中心查看余额变动。</div>
                </div>
            </div>
            <div class="res" v-if="vcode" >
                <div>活动说明：</div>
                <div>1：码中包含奖励：商城现金或再来一瓶</div>
                <div>2：每个码都包含不同奖励，只可换兌一次不限用户，请及时换兌</div>
                <div>3：换兌现金的用户请到【商城->个人中心->钱包】中查看，换兌‘再来一瓶’，请填写正确的收货信息，收到相关信息后，我们会尽快安排审核邮寄</div>
                <div>4：平台依据用户所提交的信息进行邮寄，请务必填写正确的收货信息，若因信息有误导致无法收到，概不补发。</div>
                <div>5：因活动产品为赠送产品，故运费由用户自行承担，请用户悉知</div>
            </div>
        </div>
        <div class="product_textarea">
            <div class="textarea">
                <Field　v-model="message"　rows="3"　autosize　type="textarea" maxlength="1000" placeholder="1000字+5图，就有机会评为精选品鉴哦~" show-word-limit />
            </div>
            <div class="textarea_tips">
                写15字，有机会评为<span>精选品鉴<Icon name="warning-o" /></span>
            </div>
        </div>
        <div class="product_uploader">
            <Uploader v-model="fileList" :after-read="afterRead" :before-read="beforeRead" :max-size="10000 * 1024" :max-count="5" @oversize="onOversize" />
        </div>
        <div class="Scenes">
            <Cell is-link @click="selectScenes">
                <div class="Ellipses1">
                    <i class="iconfont iconhuabanbeifen2"></i>选择用酒场景（<span v-for="(item, index) in columnsScenes" :key="index" class="tt">{{item.brand_name}}</span>）
                </div>
            </Cell>
            <Popup v-model="showScenes" position="bottom">
                <Picker show-toolbar title="选择场景" :columns="columnsScenes" value-key="brand_name" @confirm="onConfirm2" @cancel="onCancel" />
                <div class="CheckboxGroup">
                    <CheckboxGroup v-model="result">
                        <CellGroup>
                            <Cell
                            v-for="(item, index) in columnsScenes"
                            clickable
                            :key="item"
                            :title="item.brand_name"
                            @click="toggle(index)"
                            >
                            <template #right-icon>
                                <Checkbox :name="item" ref="checkboxes" />
                            </template>
                            </Cell>
                        </CellGroup>
                    </CheckboxGroup>
                </div>
            </Popup>
            <div class="yxresult">
                <span v-for="(item, index) in yxresult" :key="index">
                    <i class="iconfont iconhuabanbeifen2"></i>{{item.brand_name}}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { getActivityCode,getActivityType,uploadImg, EasyList , OccasionList, productscore, Judgesave, Draftsave, Draftdata,editJudge } from "@api/tasting";
import { Icon, Rate, Field, Uploader, Toast, Cell, Popup, Picker, CheckboxGroup, CellGroup, Checkbox, Dialog} from 'vant';
import { Image as VanImage } from 'vant';
import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
import District from "ydui-district/dist/jd_province_city_area_id";
import { wechatEvevt } from "@libs/wechat";
import { isWeixin_temp } from "@utils/index";
import cookie from "@utils/store/cookie";
export default {
    name: "Release",
    data: function() {
        return {
            id: '',
            draft_id: '', //草稿id
            showProduct: false, //选择产品
            columnsProduct: [],
            goodsinfo: '', //产品信息
            score: 5,
            message: '', //评论内容
            number:'',//核销换兌码
            showScenes: false, //选择用酒场景
            columnsScenes: [], //用酒场景列表
            yxresult: [], //已选的用酒场景, 已确认
            result: [], //已选的用酒场景, 回显已选中的

            fileList: [], //图片集合

            showdraft: false,
            code:'',
            vcode:0,
            //is_hotel:this.$store.state.app.ishotel,//是否有兑换码输入框
            //unique:this.$store.state.app.unique,//二维码编码
            exname:'',
            number:'',
            userName:'',
            postalCode:'',
            detailInfo:'',
            telNumber:'',
            city:'',
            cityShow:false,
            district: District,
        };
    },
    components: {
        VanImage, Icon, Rate, Field, Uploader, Cell, Popup, Picker, CheckboxGroup, CellGroup, Checkbox,CitySelect
    },
    created: function() {
        this.id=this.$route.query.id;
        this.code = this.$route.query.code;
        this.judgeid=this.$route.query.judgeid!=undefined?this.$route.query.judgeid:0;
        
    },
    mounted: function() {
        this.getOccasionList();
        this.gcookie(); 
        if(this.judgeid){
            this.editJudge(this.judgeid);
        }
        if(this.id){
            this.productscore(this.id);
        }
        
        // if(this.judgeId){
        //     this.editJudge(this.judgeId);
        // }
    },
    methods: {
        //
        //微信同步收货地址
            getWxAddr(){
                if(isWeixin_temp()){
                    let config = {};
                    wechatEvevt("openAddress", config)
                        .then(res => {
                            Toast.success(res)
                            this.userName = res.userName; // 收货人姓名
                            this.postalCode = res.postalCode; // 邮编
                            //this.provinceName = res.provinceName; // 国标收货地址第一级地址（省）
                            //this.cityName = res.cityName; // 国标收货地址第二级地址（市）
                            //this.countryName = res.countryName; // 国标收货地址第三级地址（国家）
                            this.detailInfo = res.detailInfo; // 详细收货地址信息
                            this.telNumber = res.telNumber; // 收货人手机号码
                            this.city = res.provinceName + "," + res.cityName + "," + res.countryName;
                        })
                        .catch(res => {
                            Toast.fail(res)
                        });
                    }
                
            },
            result2(ret) {
                this.city = ret.itemName1 + "," + ret.itemName2 + "," + ret.itemName3;
                // this.address.province = ret.itemName1;
                // this.address.city = ret.itemName2;
                // this.address.district = ret.itemName3;
            },
            clickswitchTab: function(url) {
                wx.miniProgram.switchTab({url: url})
            },
            //查看code
            seeCode(){
                if(!this.code){
                    Toast.fail({
                            message: '请重新扫码',
                            forbidClick: true,
                     });
                     return
                }
                if(!this.number){
                    Toast.fail({
                            message: '请填写授权码',
                            forbidClick: true,
                    });
                    this.vcode = 0;
                    return
                }else{
                    Toast.loading({
                        duration: 0,
                        message: '查看中...',
                        forbidClick: true,
                    });
                    getActivityType({
                        code:this.code,
                        number:this.number
                    }).then(res=>{
                        Toast.clear();
                        if(res.data.vcode==0){
                            Toast.fail({
                                message: '此码已被换兌或非活动授权码',
                                forbidClick: true,
                            });
                        }else{
                            this.exname=="on"
                        }
                        this.vcode = res.data.vcode;
                    }).catch(err=>{
                         Toast.clear();
                         Toast.fail({
                                message: err,
                                forbidClick: true,
                            });
                    })
                }
            },
            //兑换 新
            exchange(){
                let that = this;
                if(this.userName==''){
                    return Dialog.error("请填写收货人姓名！");
                }
                if(this.address==''){
                    return Dialog.error("请填写收货详情地址！");
                }
                if(this.telphone==''){
                    return  Dialog.error("请填写收货人手机号！");
                }
                if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.telphone)) {
                    return  Dialog.error("请填写正确的手机号");
                }
                if(this.city==''){
                    return  Dialog.error("请选择省市区");
                }

                if(this.exname=="on"){
                    Toast.loading({
                        message: '兑换中',
                        forbidClick: true,
                    });
                    getActivityCode({
                        code:this.code,
                        number:this.number,
                        username:this.userName,
                        postalCode:this.postalCode,
                        address:this.detailInfo,
                        telphone:this.telNumber,
                        city:this.city,
                    }).then(res=>{
                        Toast.clear();
                        Toast.success({
                            message: res.msg,
                            forbidClick: true,
                        });
                        if(that.vcode==2){
                            Dialog.confirm({
                                mes: "前往商城逛逛?",
                                opts() {
                                this.clickswitchTab('/pages/index/index')
                                .then(res => {
                                    //Dialog.success("取消成功");
                                })
                                .catch(err => {
                                    //Dialog.error("失败");
                                });
                                }
                            });
                        }
                    }).catch(err => {
                        Toast.fail({
                            message: err.msg,
                            forbidClick: true,
                        });
                    })
                }
            },

        //cheng
        editJudge(jid){
            editJudge({id:jid}).then(res=>{
                var editdata = res.data.data;
                if(editdata.examine_status!=2){
                    Toast.fail({
                        message: '无法编辑当前状态文章',
                        forbidClick: true,
                    });
                }else{
                    editdata.images.forEach((item)=>{
                    this.fileList.push({'url':item});
                    })
                    this.message = editdata.content;
                    this.yxresult = editdata.occasion;
                    this.id = editdata.product_id;
                    this.productscore(editdata.product_id);
                } 
            })
        },
        //发布
        Judgesave(){
            if(this.vcode==1){
                if(this.userName==''){
                    Toast.fail({
                    message: '请填写收货人姓名！',
                    forbidClick: true,
                });
                return
                }
                if(this.address==''){
                    Toast.fail({
                    message: '请填写收货详情地址！',
                    forbidClick: true,
                });
                return
                }
                if(this.telNumber==''){
                    Toast.fail({
                    message: '请填写收货人手机号！',
                    forbidClick: true,
                });
                    return
                }
                if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.telNumber)) {
                    Toast.fail({
                    message: '请填写正确的手机号',
                    forbidClick: true,
                    });
                     return
                }
                if(this.city==''){
                    Toast.fail({
                    message: '请选择省市区',
                    forbidClick: true,
                });
                return
                }
            }
            if(!this.goodsinfo.id){
                Toast.fail({
                    message: '请选择产品',
                    forbidClick: true,
                });
                return
            }
            if(!this.message){
                Toast.fail({
                    message: '内容不能为空',
                    forbidClick: true,
                });
                return
            }
            if(this.fileList.length==0){
                Toast.fail({
                    message: '请上传图片',
                    forbidClick: true,
                });
                return
            }
            if(this.yxresult.length==0){
                Toast.fail({
                    message: '请选择用酒场景',
                    forbidClick: true,
                });
                return
            }
            if(this.unique==undefined){
                this.unique=='';
            }
            let image=[];
            let category=[]; 
            this.fileList.forEach(function(item) {
                image.push(item.url)
            });
            this.yxresult.forEach(function(item) {
                category.push(item.id)
            });
            Toast.loading({
                duration: 0,
                message: '发布中...',
                forbidClick: true,
            });
            Judgesave({
                id: this.judgeid,
                draft_id: this.draft_id, //草稿id
                product_id: this.goodsinfo.id,
                score: this.score * 2, //评分
                brand_id: this.goodsinfo.brand_id, //品牌id
                content: this.message, //内容
                image: image, //图片集合
                category: category, //用酒场景集合
                number:this.number,//换兌码
                unique:this.code,//二维码编码
                username:this.userName,
                postalCode:this.postalCode,
                address:this.detailInfo,
                telphone:this.telNumber,
                city:this.city,
            }).then(res => {
                Toast.success({
                    message: res.msg,
                    forbidClick: true,
                });
                if(this.showdraft){
                    cookie.remove('draft');
                }
                var that = this;
                setTimeout(function(){ 
                    that.$router.back(-1);
                }, 2000);
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        gcookie(){
            if(cookie.get('draft')){
                Dialog.confirm({
                    title: '是否使用草稿？',
                })
                .then(() => {
                    this.showdraft=true;
                    let data=cookie.get('draft');
                    this.message=data.message;
                    this.fileList=data.fileList;
                    this.yxresult=data.yxresult;
                    this.productscore(data.goodsinfoid);
                })
                .catch(() => {
                    // on cancel
                });
                
            }
        },
        //存草稿
        draftsave(){
            if(!this.goodsinfo.id){
                Toast.fail({
                    message: '请选择产品',
                    forbidClick: true,
                });
                return
            }
            if(!this.message){
                Toast.fail({
                    message: '内容不能为空',
                    forbidClick: true,
                });
                return
            }
            if(this.fileList.length==0){
                Toast.fail({
                    message: '请上传图片',
                    forbidClick: true,
                });
                return
            }
            if(this.yxresult.length==0){
                Toast.fail({
                    message: '请选择用酒场景',
                    forbidClick: true,
                });
                return
            }
            let draft={
                goodsinfoid: this.goodsinfo.id,
                score: this.score,
                message: this.message,
                fileList: this.fileList,
                yxresult: this.yxresult,
            };
            cookie.set('draft', draft);
            Toast.success({
                message: '保存成功',
                forbidClick: true,
            });
            this.goodsinfo='';
            this.score=5;
            this.message='';
            this.fileList=[];
            this.yxresult=[];
        },
        // 用酒场景
        getOccasionList(){
            OccasionList().then(res => {
                this.columnsScenes=res.data;
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        toggle(index) {
            this.$refs.checkboxes[index].toggle();
        },

        selectproduct(){
            this.showProduct = true;
            this.columnsProduct=[];
            this.getEasyList();
        },
        getEasyList(){
            EasyList().then(res => {
                let datalist = res.data;
                datalist.forEach(item => {
                    item.abbreviation_name=item.brand_name;
                    if(item.product.length > 0){
                        item.children=item.product;
                    }else{
                        item.children=[{abbreviation_name:''}];
                    }
                });
                this.columnsProduct=datalist;
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        onConfirm(value, index) {
            this.showProduct = false;
            let one=index[0];
            let two=index[1];
            if(this.columnsProduct[one].children[two].id){
                this.productscore(this.columnsProduct[one].children[two].id);
            }
        },
        productscore(id){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            productscore({id:id}).then(res => {
                Toast.clear();
                this.goodsinfo=res.data;
                this.score=res.data.score / 2;
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        onConfirm2(value, index) {
            this.showScenes = false;
            this.yxresult=[];
            this.result.forEach(item=>{
                this.yxresult.push(item);
            })
        },
        onCancel() {
            this.showProduct = false;
            this.showScenes = false;
        },
        selectScenes() {
            this.showScenes = true;
            this.result=[];
            this.yxresult.forEach(item=>{
                this.result.push(item);
            })
        },

        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file);
            let index = this.fileList.length - 1;
            file.status = 'uploading';
            file.message = '上传中...';
            var formData = new FormData();
　　        formData.append('file', file.file);
            Toast.loading({
                duration: 0,
                message: '上传中',
                forbidClick: true,
            });
            uploadImg(formData).then(res => {
                Toast.clear();
                this.fileList[index] = res.data;
                file.status = '';
                file.message = '';
                console.log(this.fileList)
            })
            .catch(err => {
                this.fileList.splice(index,1);
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        beforeRead(file) {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                Toast('只允许上传jpg/png格式的图片！');
                return false;
            }
            return true;
        },
        onOversize(file) {
            console.log(file);
            Toast('图片大小不能超过 10M');
        },
    }
};
</script>
<style scoped lang="scss">
::v-deep{
    .van-icon-star{font-size: 0.5rem!important;}
    .van-uploader__upload, .van-uploader__preview-image{border: 2px solid #eee;border-radius: 0.2rem;}
    .Scenes{
        .van-picker__columns{display:none;}
    }
}
    .Release{
        padding-top: 1rem;
        .hdcon{
            position: fixed;top: 0;left: 0;right: 0;background: #fff;border-bottom: 1px solid #f5f5f5;
            z-index: 2;
            .hd{
                text-align: center;overflow:hidden;line-height: 1rem;position: relative;color: #000;font-size: 0.32rem;
                i{position: absolute;top: 0;left: 0;line-height: 1rem;font-size: 0.4rem;padding: 0 0.1rem;}
                .btn{
                    position: absolute;top: 0.2rem;right: 0;
                    button{color:#999;font-size: 0.24rem;border: 1px solid #eee;display: block;float: left;height: 0.6rem;line-height: 0.6rem;margin-right: 0.1rem;border-radius: 3rem;padding: 0 0.2rem;}
                    .fa{color:#333;background: #f9dc54;padding: 0 0.3rem;border: 1px solid #fff;}
                }
            }
        }
        .slect_product{
            margin: 0.3rem;text-align: center;box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.16);line-height: 1.6rem;border-radius: 0.2rem;font-size: 0.28rem;
            i{color:#999;}
        }
        .product{
            margin-top: 0.4rem;border-top: 1px solid #d9d9d9;padding: 0.2rem;overflow:hidden;
            .dpic{width: 1rem;float:left;margin: 0 0.8rem;}
            .con{
                padding-left: 2.8rem;color:#000;
                .name{font-size: 0.36rem;margin-top: 0.4rem;}
                .fen{
                    font-size: 0.36rem;margin: 0.2rem 0;
                    span{font-size: 0.6rem;}
                }
            }
        }
        .product_ver{
                padding: 0.2rem;border-top: 0.2rem solid #f5f5f5;
                .search{
                    border: 2px solid #eee;
                    margin: 0.2rem auto;
                    background: #fff;
                    //border-radius:0.3rem;
                    padding: 0.2rem;
                    position: relative;
                    z-index: 1;
                input{
                    width: 100%;
                }
                .verification{
                    position: absolute;
                    background: #f00;
                    padding: 0.1rem 0.2rem;
                    right: 0.1rem;
                    border-radius: 6px;
                    top: 0.1rem;
                    color:#fff;
                }
                
            }
            .codeInfo{
                    background: #fff;
                    width: 95%;
                    padding: 0.2rem;
                    margin: 0 auto;
                    border-radius:0.3rem;
                    .info{
                            width: 70%;
                            margin-left:5% ;
                            .red-font{
                                color: red;
                                font-size: 0.6rem;
                            }
                        }
                    .prize-wine{
                        input{
                            display: block;
                            padding: 0.2rem;
                            border-bottom: 1px solid rgb(231, 231, 231);
                            //border-radius:20px;
                            width: 95%;
                            margin: 10px auto;
                        }
                        .weixinaddr{
                            text-align: center;
                            color:rgb(23, 163, 4);
                            padding-top:0.4rem;
                        }
                    }
                    .prize-money{
                        
                    }
                }
                .res{
                    width: 90%;
                    margin: 0 auto;
                    color: rgb(131, 131, 131);
                    font-size: 0.2rem;
                    div{
                        padding-top: 0.1rem;
                    }  
        }
        }
        .product_textarea{
            padding: 0.2rem;border-top: 0.2rem solid #f5f5f5;
            .textarea{
                border: 2px solid #eee;overflow:hidden;
            }
            .textarea_tips{
                text-align:right;color:#999;font-size: 0.24rem;line-height:0.5rem;padding: 0.05rem 0;
                span{
                    color:#568af8;padding: 0 0.05rem;
                    i{font-size: 0.3rem;float:right;line-height: 0.48rem;}
                }
            }
        }
        .product_uploader{
            padding: 0 0.2rem;
        }
        .Scenes{
            border-top: 0.2rem solid #f5f5f5;
            .CheckboxGroup{max-height:8rem;min-height:3rem;overflow-y:scroll;}
            .yxresult{
                min-height: 3rem;padding: 0.1rem 0.1rem;
                span{
                    display:inline-block;font-size: 0.24rem;line-height: 0.3rem;padding: 0.2rem;border-radius: 0.1rem;box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.1);margin: 0.1rem;
                }
            }
        }
        .Scenes .tt{color: #999;margin: 0 0.05rem;}
        .Scenes .select{line-height: 0.8rem;padding: 0 0.6rem 0 0.2rem;border-bottom: 1px solid #eee;position: relative;}
        .Scenes .select .jiantou{position: absolute;top: 0;right: 0.2rem;}
        .Scenes .select .selectt{color: #999;margin-right: 0.1rem;font-size: 24rpx;}
        
    }
</style>